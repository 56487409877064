import './scripts-bs4/ajaxTabs'
import modal from '#eproc/modal.js'

(function($, document, window) {
    $(document).ready(function() {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover({
            placement: 'right',
            trigger: 'hover focus',
            fallbackPlacement: ['right', 'top', 'left', 'bottom'],
        })

        //mobile-search
        $('#search-toggle').click(function(e) {
            setTimeout(function() { $('input[name=txtNumProcessoPesquisaRapida]').focus() }, 500)
        })

        $('#search-close').click(function(e) {
            e.preventDefault()
            $('#mobile-search').toggleClass('mobile-search-visible mobile-search-invisible')
        })

        //search-filter
        $('.search-panel .dropdown-menu').find('a').click(function(e) {
            e.preventDefault()
            var param = $(this).attr('href').replace('#', '')
            var concept = $(this).text()
            $('.search-panel span#search_concept').text(concept)
            $('.input-group #search_param').val(param)
        })

        //#vermaismenos
        if ($('#collapseSummary').height() < 105) {
            $('#divVerMaisMenos').attr('style', 'display: none !important')
        }

        //botões-dropdown dentro da uma linha clicável de tabela
        $('a.table-btn').click(function(e) {
            e.stopPropagation()
            $(this).next('.dropdown-menu').toggle()
        })

        //botões dropdown dentro de uma table-responsive
        $('.table-responsive').on('show.bs.dropdown', function() {
            $('.table-responsive').css('overflow', 'inherit')
        })
        $('.table-responsive').on('hide.bs.dropdown', function() {
            $('.table-responsive').css('overflow', 'auto')
        })

        $('#mostrar-btn-padrão').click(function() {
            $('.btn-escolher-padrao').toggleClass('d-none d-block')
        })

        //collapse - expandir/recolher todos
        function expandAll() {
            $('.collapsible-header').addClass('active')
            $('.collapsible').collapsible({accordion: false})
        }

        function collapseAll() {
            $('.collapsible-header').removeClass(function() {
                return 'active'
            })
            $('.collapsible').collapsible({accordion: true})
            $('.collapsible').collapsible({accordion: false})
        }

        //stop-propagation class
        $('.stop-propagation').on('click', function(e) {
            e.stopPropagation()
        })

        //swicthes -show/hide target
        $('.switch-toggle').change(function() {
            const TARGET = '#' + $(this).attr('data-target')
            const IS_CHECKED = $(this).is(':checked')
            IS_CHECKED ? $(TARGET).show() : $(TARGET).hide()
            const NAME = $(this).attr('id')
            const DATE = new Date()
            const SEIS_MESES = 6 * 30 * 24 * 60 * 60 * 1000
            DATE.setTime(DATE.getTime() + SEIS_MESES)
            const EXPIRES = 'expires=' + DATE.toUTCString()
            const COOKIE = NAME + '=' + IS_CHECKED + ';' + EXPIRES
            document.cookie = COOKIE
        })

        modal.init('.modal')

    })

    window.showReadMore = showReadMore
})($, document, window)

$(document).ready(function() {
    $('.floatingButton').on(
        'click',
        function(e) {
            e.preventDefault()
            $(this).toggleClass('open')
            if ($(this).children('.fa').hasClass('fa-plus')) {
                $(this).children('.fa').removeClass('fa-plus')
                $(this).children('.fa').addClass('fa-close')
            } else if ($(this).children('.fa').hasClass('fa-close')) {
                $(this).children('.fa').removeClass('fa-close')
                $(this).children('.fa').addClass('fa-plus')
            }
            $('.floatingMenu').stop().slideToggle()
        },
    )
    $(this).on('click', function(e) {
        var container = $('.floatingButton')

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && $('.floatingButtonWrap').has(e.target).length === 0) {
            if (container.hasClass('open')) {
                container.removeClass('open')
            }
            if (container.children('.fa').hasClass('fa-close')) {
                container.children('.fa').removeClass('fa-close')
                container.children('.fa').addClass('fa-plus')
            }
            $('.floatingMenu').hide()
        }
    })
})

$(document).ready(function() {
    $('.switch-toggle').change(function() {
        var target = $(this).attr('data-target')
        if ($(this).is(':checked')) {
            $('#' + target).show()
        } else {
            $('#' + target).hide()
        }
    })
})

//vermaisvermenos componente
$(document).on('click', '.btn-read-more', function() {
    var btn = $(this)
    var target = btn.parent('div').prev('.show-read-more')
    var divCollapsedHeight = target.attr('read-more-collapsed-height')
    var divHeight = target.attr('read-more-height')

    if (btn.text() == '+ Ver mais') {
        btn.text('- Ver menos')
        showReadMoreText(target, divHeight)
    } else {
        btn.text('+ Ver mais')
        hideReadMoreText(target, divCollapsedHeight)
    }
})

function showReadMore() {
    var btnReadMore = '<div class="wrapper-btn-read-more"><a href="#/" class="btn-read-more">+ Ver mais</a></div>'

    $('.show-read-more').each(function() {
        var el = $(this)
        var linesLimit = 3

        el.next('.wrapper-btn-read-more').remove()

        el.css('height', 'auto')
        el.attr('id', 'srm')

        if (el.attr('lines-limit')) {
            linesLimit = el.attr('lines-limit')
        }

        var divHeight = parseInt(el.css('height'), 10)
        el.attr('read-more-height', divHeight)

        var lineHeight = parseInt(el.css('line-height'), 10)
        var divMaxHeight = lineHeight * linesLimit
        el.attr('read-more-collapsed-height', divMaxHeight)

        if (divHeight > divMaxHeight) {
            hideReadMoreText(el, divMaxHeight)
            el.after(btnReadMore)
        }
    })
}

function hideReadMoreText(el, divCollapsedHeight) {
    el.css('height', divCollapsedHeight)
}

function showReadMoreText(el, divHeight) {
    el.css('height', divHeight)
}

$(document).ready(function() {
    showReadMore()
})

$(window).resize(function() {
    showReadMore()
})


//Responsive actions menu

/* Utilizar a classe .menu-actions-responsive na div pai e o atributo html 'menu-count', combinando-o com os breakpoints do bootstrap
* Exemplo: menu-count="xs-2 md-4"  -> Neste exemplo, no breakpoint xs serão mostrados dois itens de menu. A partir do breakpoint MD, serão mostrados 4. Os elementos que excederem,
* serao deslocados para um dropdown. */

function createDropdown() {
    return $(
        '<div class="dropdown">' +
        '<a href="#/" class="btn-dropdown-actions" data-toggle="dropdown" aria-haspopup="true" title="Mais ações">' +
        '<i class="material-icons text-dark">more_vert</i>' +
        '</a>' +
        '<div role="menu" class="dropdown-menu"></div>' +
        '</div>'
    );
}

window.adjustActionsMenu = function() {
    $('.menu-actions-responsive').each(function() {
        var $menu = $(this);
        var counts = $menu.attr('menu-count').split(' ');

        var breakpoints = {
            'xs': 0,
            'sm': 576,
            'md': 768,
            'lg': 992,
            'xl': 1200
        };

        var showCount = Infinity;
        var windowWidth = $(window).width();

        // Determine the number of visible items based on the current window width
        counts.forEach(function(count) {
            var parts = count.split('-');
            var breakpoint = parts[0];
            var num = parseInt(parts[1]);

            if (windowWidth >= breakpoints[breakpoint]) {
                showCount = num;
            }
        });

        var $links = $menu.find('a').not('.btn-dropdown-actions');
        var $dropdown = $menu.find('.dropdown');
        var $dropdownMenu;

        // Create dropdown if it doesn't exist
        if ($dropdown.length === 0) {
            $dropdown = createDropdown();
            $menu.append($dropdown);
        }

        $dropdownMenu = $dropdown.find('.dropdown-menu');

        // Ensure the dropdown is empty and only populate it with excess links
        $dropdownMenu.empty();
        $links.each(function(index) {
            if (index >= showCount) {
                //definir label do dropdown-item
                var label = '';
                if ($(this).attr('data-content')) {
                    label = $(this).attr('data-content');
                } else {
                    label = $(this).attr('title');
                }

                $(this).appendTo($dropdownMenu).addClass('dropdown-item');
                if($(this).find('span.responsive-label').length === 0){
                    $(this).find('i').after('<span class="responsive-label">' + label + '</span>')
                }
            } else {
                $(this).removeClass('dropdown-item').insertBefore($dropdown);
                $(this).find('span.responsive-label').remove();
            }
        });

        // Hide or show dropdown based on the number of items in it
        if ($dropdownMenu.children().length === 0) {
            $dropdown.hide();
        } else {
            $dropdown.show();
        }
    });
}

function checkResponsiveMenu() {
    if ($('.menu-actions-responsive').length > 0) {
        adjustActionsMenu();
    }
}

$(document).ready(checkResponsiveMenu);
$(document).ajaxComplete(checkResponsiveMenu);
$(window).resize(checkResponsiveMenu);

//Abas responsivas - Responsive tabs
window.responsiveTabs = function() {
    $('.nav-tabs-responsive').each(function() {

        var outer = $(this);

        var setInvisible = function(elem) {
            elem.css('visibility', 'hidden');
        };
        var setVisible = function(elem) {
            elem.css('visibility', 'visible');
        };

        // Verifica se o conteúdo transborda horizontalmente
        var isOverflowing = outer.prop('scrollWidth') > outer.prop('clientWidth');

        // Atualiza a visibilidade dos botões de rolagem com base no overflow
        var btnLeft = outer.prevAll('.tabs-scroll-left').first();
        var btnRight = outer.nextAll('.tabs-scroll-right').first();

        if (isOverflowing) {
            if (btnLeft.length == 0) {
                outer.before('<div class="tabs-scroll tabs-scroll-left" style="visibility: hidden;"><a href="#"><i class="bi bi-caret-left-fill"></i></a></div>');
                btnLeft = outer.prevAll('.tabs-scroll-left').first();
            }
            if (btnRight.length == 0) {
                outer.after('<div class="tabs-scroll tabs-scroll-right"><a href="#"><i class="bi bi-caret-right-fill"></i></a></div>');
                btnRight = outer.nextAll('.tabs-scroll-right').first();
            }

            // Define a visibilidade inicial dos botões
            if (outer.scrollLeft() <= 0) {
                setInvisible(btnLeft);
            } else {
                setVisible(btnLeft);
            }

            if (outer.scrollLeft() + outer.width() >= outer.prop('scrollWidth')) {
                setInvisible(btnRight);
            } else {
                setVisible(btnRight);
            }
        } else {
            // Se não houver overflow, garante que os botões estejam escondidos
            setInvisible(btnLeft);
            setInvisible(btnRight);
        }

        // Eventos de clique para os botões de rolagem
        btnRight.off('click').on('click', function() {
            setVisible(btnLeft);
            var leftPos = outer.scrollLeft();
            outer.animate({
                scrollLeft: leftPos + 200
            }, 200, function() {
                if (outer.scrollLeft() + outer.width() >= outer.prop('scrollWidth')) {
                    setInvisible(btnRight);
                }
            });
        });

        btnLeft.off('click').on('click', function() {
            setVisible(btnRight);
            var leftPos = outer.scrollLeft();
            outer.animate({
                scrollLeft: leftPos - 200
            }, 200, function() {
                if (outer.scrollLeft() <= 0) {
                    setInvisible(btnLeft);
                }
            });
        });
    });
}

function checkResponsiveTabs() {
    if ($('.nav-tabs-responsive').length > 0) {
        responsiveTabs();
    }
}

$(document).ready(checkResponsiveTabs);
$(document).ajaxComplete(checkResponsiveTabs);
$(window).resize(checkResponsiveTabs);


//Solucao de contorno para quando o menu do dropdown excede a margem direita da tela
$(document).ready(function() {
    function adjustDropdownPosition() {
        $('.dropdown').each(function() {
            var $dropdown = $(this);
            var $button = $dropdown.find('.btn-dropdown-actions');
            var $menu = $dropdown.find('.dropdown-menu');

            // Verificar se o menu e o botão estão definidos
            if ($button.length && $menu.length) {
                // Remove classes antigas
                $menu.removeClass('dropdown-menu-left dropdown-menu-right');

                // Calcula a posição e as dimensões do botão e do menu
                var buttonRect = $button[0].getBoundingClientRect();
                var menuRect = $menu[0].getBoundingClientRect();
                var windowWidth = $(window).width();

                // Posição final do menu
                var menuRight = menuRect.right;

                // Verifica se o menu ultrapassa a borda direita da janela
                var overflowRight = menuRight > windowWidth;

                if (overflowRight) {
                    // Ajusta a posição do menu para a esquerda
                    var adjustedLeft = Math.max(0, windowWidth - menuRect.width);
                    $menu.addClass('dropdown-menu-left').css('left', adjustedLeft - buttonRect.left + 'px');
                } else {
                    // Alinha à direita se não houver overflow
                    $menu.removeClass('dropdown-menu-right').css('left', '0px');
                }
            }
        });
    }

    // Ajustar a posição do dropdown quando o dropdown for exibido
    $(document).on('show.bs.dropdown', function () {
        // Ajusta a posição do dropdown
        setTimeout(adjustDropdownPosition, 10); // Usar setTimeout para garantir que o dropdown seja exibido
    });

    // Ajustar a posição também quando a janela for redimensionada
    $(window).on('resize', function() {
        adjustDropdownPosition();
    });
});

